import { SelfDesignLayer, SelfDesignLayerGroup, SelfDesignSide } from "@/core/type";

export const SELF_DESIGN_ACTION = {
    LAYER_GROUP_CHANGE: "layerGroupChange" as const,
    SET_LAYER_GROUP: "setLayerGroup" as const,
    
    DELETE_LAYER: "deleteLayer" as const,
    LOCK_LAYER: "lockLayer" as const,
    
    SIDE_CHANGE: "sideChange" as const,
    SET_SIDE: "setSide" as const,
};

export type SideState = {
    sideList: SelfDesignSide[];
    currentSide: SelfDesignSide | null;
}

export type LayerState = {
    layerGroupList: SelfDesignLayerGroup[];
    currentLayerGroup: SelfDesignLayerGroup | null;
    deleteLayer: SelfDesignLayer | null;
    lockLayer: SelfDesignLayer | null;
}