import { SelfDesignLayer, SelfDesignLayerGroup } from '@/core/type';
import produce from 'immer';
import { SelfDesignActionType } from './actions';
import { LayerState, SELF_DESIGN_ACTION, SideState } from './type';

const layerInitialState: LayerState = {
    layerGroupList: [],

    deleteLayer: null,
    lockLayer: null,
    
    currentLayerGroup: null
}

const sideInitialState: SideState = {
    sideList: [],
    currentSide: null
}

export const SelfDesignLayerReducer = (
    state: LayerState = layerInitialState,
    action: SelfDesignActionType ): LayerState => {
    switch( action.type ) {
        case SELF_DESIGN_ACTION.LAYER_GROUP_CHANGE:
            return produce(state, ( draft ) => {
                draft.currentLayerGroup = action.payload.layerGroup;
                
                let idx: number = 0;

                for( idx = 0; idx < draft.layerGroupList.length; idx++ ) {
                    const group: SelfDesignLayerGroup = draft.layerGroupList[ idx ];

                    if( group.id === draft.currentLayerGroup.id ) {
                        draft.layerGroupList[ idx ] = draft.currentLayerGroup;
                        break;
                    }
                }
            });
        case SELF_DESIGN_ACTION.SET_LAYER_GROUP:
            return produce(state, ( draft ) => {
                draft.layerGroupList = action.payload.layerGroupList;
                draft.currentLayerGroup = draft.layerGroupList[0];
            });
        case SELF_DESIGN_ACTION.DELETE_LAYER:
            return produce(state, ( draft ) => {
                const resultList: SelfDesignLayer[] | undefined = draft.currentLayerGroup?.layerList
                    .filter((item) => {
                        return item.id != action.payload.layer.id;
                    });
                
                const layerGroupList: SelfDesignLayerGroup[] | undefined = draft.layerGroupList.map((group) => {
                    if(group.layerList && group.layerList.length > 0) {
                        group.layerList = group.layerList.filter((item) => {
                            return item.id != action.payload.layer.id;
                        });
                    }

                    return group;
                });

                if(draft.currentLayerGroup != null && resultList != null) {
                    draft.currentLayerGroup.layerList = resultList;
                    draft.layerGroupList = layerGroupList;
                }
            });
        case SELF_DESIGN_ACTION.LOCK_LAYER:
            return produce(state, ( draft ) => {
                const resultList: SelfDesignLayer[] | undefined = draft.currentLayerGroup?.layerList.map((item) => {
                    if(item.id === action.payload.layer.id) {
                        item.isLock = !item.isLock;
                    }

                    return item;
                });

                if(draft.currentLayerGroup != null && resultList != null) {
                    draft.currentLayerGroup.layerList = resultList;
                }
            });
        default:
            return state
    }
}

export const SelfDesignSideReducer = (
    state: SideState = sideInitialState,
    action: SelfDesignActionType ): SideState => {
    switch( action.type ) {
        case SELF_DESIGN_ACTION.SIDE_CHANGE:
            return produce(state, ( draft ) => {
                draft.currentSide = action.payload.currentSide;
            });
        case SELF_DESIGN_ACTION.SET_SIDE:
            return produce(state, (draft) => {
                draft.sideList = action.payload.sideList;

                if( !draft.currentSide ) {
                    draft.currentSide = draft.sideList[0];
                }
            });
        default:
            return state
    }
}