import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import AppStore from '@/store';
import PageLoader from '@components/loader/page-loader';
import { Application } from '@core/application';
import ObjectUtil from '@utils/ObjectUtil';
import HttpRequest from '@core/http';

const App = React.lazy(() => import('@/App'));

async function initialize(): Promise<void> {
    try {
        await Application.getInstance().initialize();
        const token: string | null = localStorage.getItem("token");
    
        if( ObjectUtil.isNotEmpty(token)) {
            HttpRequest.userToken = token!;
        }
    } catch( err ) {
        console.error( err );
    }

    createApp();
}

function createApp(): void {
    ReactDOM
        .createRoot(document.getElementById('root') as HTMLElement)
        .render(
            <Provider store={AppStore}>
                <Suspense fallback={PageLoader}>
                    <App />
                </Suspense>
                </Provider>
        );
}

initialize();